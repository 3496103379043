@import 'assets/styles/imports';

.player {
	min-height: 73px;
	user-select: none;

	&--no-audio {
		display: flex;
		padding-top: 10px;
	}

	&__text {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		font-size: 14px;
		line-height: 20px;
		color: rgba(#ffffff, 0.4);
	}

	&__body {
		padding-top: 38px;
	}

	&__audio {
		display: none;
	}

	&__progressbar {
		line-height: 0;
	}

	&__progressbar-range {
		--range-progress: 0%;

		position: relative;
		width: 100%;
		height: 4px;
		margin-bottom: 12px;
		background: rgba(255, 255, 255, 0.21);
		-webkit-appearance: none;
		cursor: pointer;

		&::-moz-range-track {
			position: relative;
			width: 100%;
			height: 4px;
			background: rgba(255, 255, 255, 0.21);
			cursor: pointer;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: var(--range-progress);
			height: 4px;
			background: #ffed00;
		}

		&::-moz-range-progress {
			height: 4px;
			background: #ffed00;
		}

		&::-webkit-slider-thumb {
			position: relative;
			height: 20px;
			width: 20px;
			background-color: #ffed00;
			border-radius: 50%;
			border: none;
			cursor: pointer;
			-webkit-appearance: none;
		}

		&:active::-webkit-slider-thumb {
			transform: scale(1.2);
		}

		&::-moz-range-thumb {
			position: relative;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			background: #ffed00;
			border: transparent;
			cursor: pointer;
		}

		&:active::-moz-range-thumb {
			transform: scale(1.2);
		}
	}

	&__progressbar-times {
		display: flex;
		justify-content: space-between;
	}

	&__progressbar-time {
		font-size: 15px;
		line-height: 18px;
		letter-spacing: -0.24px;
		color: #9299a2;
	}

	&__controls {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__controls-skip {
		display: flex;
		flex-direction: column;
		width: 32px;
		height: 32px;
		transition: opacity $duration;

		&[disabled] {
			opacity: 0.25;
			cursor: pointer;
		}

		svg {
			margin: auto;
			pointer-events: none;
		}
	}

	&__controls-play {
		display: flex;
		flex-direction: column;
		width: 56px;
		height: 56px;
		margin-left: 28px;
		margin-right: 28px;
		background: rgba(255, 255, 255, 0.06);
		border-radius: 16px;

		svg {
			margin: auto;
			pointer-events: none;
		}
	}

	&__controls {
		position: relative;
	}

	&__select {
		position: absolute;
		top: 8px;
		left: 20px;
		z-index: 1;

		@include mobile-small {
			left: 0;
		}
	}

	&__select-toggle {
		display: block;
		width: 40px;
		height: 40px;
		left: 21px;
		top: 19px;
		text-align: center;
		font-weight: 600;
		font-size: 15px;
		line-height: 40px;
		text-align: center;
		letter-spacing: -0.24px;
		color: #ffffff;
		background: none;
		transition: background $duration;
		border-radius: 8px;

		&--active {
			background: rgba(255, 255, 255, 0.06);
		}
	}

	&__select-dropdown {
		position: absolute;
		width: 120px;
		left: 0;
		bottom: calc(100% + 8px);
		background-color: #1c1c1e;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
			0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		z-index: 1;
	}

	&__select-item {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);

		&:last-child {
			border-bottom: none;
		}
	}

	&__select-button {
		display: block;
		text-align: left;
		width: 100%;
		padding: 13px 16px 12px;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.43px;
		color: #ffffff;
	}
}
