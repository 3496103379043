@import 'assets/styles/_imports';

.alert {
	width: 300px;
	border-radius: 8px;
	background-color: $backgroundPrimary;
	margin-top: 38px;
	margin-bottom: auto;

	@include mobile-medium-min {
		width: calc(100% - 40px);
		max-width: 500px;
	}

	&__header {
		padding: 19px 16px;

		@include mobile-medium-min {
			padding: 20px 20px;
		}
	}

	&__title {
		font-size: 16px;
		line-height: 21px;
		font-weight: 600;
		color: $textPrimary;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;

		@include mobile-medium-min {
			font-size: 20px;
			line-height: 28px;
			font-weight: 500;
			text-align-last: left;
			text-align: left;
		}
	}

	&__subtitle {
		font-size: 13px;
		line-height: 16px;
		color: $textSecond;
		text-align: center;
		margin-top: 7px;

		@include mobile-medium-min {
			text-align: left;
		}
	}

	&__btns {
		display: flex;
		flex-direction: column;

		@include mobile-medium-min {
			flex-direction: row;
			justify-content: flex-end;
		}
	}

	&__btn {
		padding: 11px 16px;
		font-size: 16px;
		font-weight: 600;
		line-height: 21px;
		color: $primary;
		border-bottom: 1px solid #e5e5e5;

		@include mobile-medium-min {
			font-size: 14px;
			line-height: 24px;
			text-transform: uppercase;
		}

		&:last-child {
			border-bottom: none;
		}

		&--destructive {
			color: $error;
		}

		&--success {
			color: $success;
		}
	}
}
