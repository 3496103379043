@import 'assets/styles/_imports';

.profile-main {
	display: flex;
	flex-direction: column;

	&__avatar {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 23px;
	}

	&__avatar-image {
		position: relative;
		width: 66px;
		height: 66px;
		border-radius: 50%;
		margin-bottom: 14px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__avatar-change {
		font-size: 13px;
		line-height: 18px;
		color: $primary;

		@include mobile-medium-min {
			padding: 5px;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
		}
	}

	&__status {
		position: absolute;
		top: calc(100% - 9px);
		left: 50%;
		padding: 1px;
		background-color: #fff;
		border-radius: 4px;
		transform: translateX(-50%);
		height: 20px;
		display: flex;
		align-items: center;
	}

	&__status-item {
		height: 16px;
		padding: 0 4px;
		text-align: center;
		color: $textWhite;
		font-size: 12px;
		line-height: 16px;
		border-radius: 4px;

		&--club {
			background-color: #00ab4e;
		}

		&--silver {
			background-color: #7a7a7a;
		}

		&--gold {
			background-color: #877342;
		}

		&--platinum {
			background-color: #292929;
		}
	}

	&__name {
		display: flex;
		flex-direction: column;
	}

	&__label {
		font-size: 12px;
		line-height: 20px;
		color: $textPrimaryOpacity60;
		margin-bottom: 1px;
	}

	&__input {
		width: 100%;
		border: 1px solid #d5d3d3;
		border-radius: 4px;
		height: 48px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
		background-color: #fafafa;
	}

	&__input-text {
		font-size: 16px;
		line-height: 20px;
		color: $textSecond;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__input-edit {
		font-size: 16px;
		line-height: 20px;
		color: $primary;
		margin-left: 5px;
	}

	&__input-wrapper {
		display: flex;
		align-items: center;
		max-width: 100%;
		overflow: hidden;

		svg {
			margin-left: 5px;
			flex-shrink: 0;
		}
	}
}
