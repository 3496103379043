$primary: rgba(0, 171, 78, 1); // #00AB4E
$primaryOpacity20: rgba(0, 171, 78, 0.2);
$primaryOpacity40: rgba(0, 171, 78, 0.4);
$secondary: #0d6eff;

$textPrimary: rgba(41, 41, 41, 1); // #292929
$textPrimaryOpacity80: rgba(0, 0, 0, 0.8);
$textPrimaryOpacity60: rgba(0, 0, 0, 0.6);
$textPrimaryOpacity40: rgba(0, 0, 0, 0.4);
$textPrimaryOpacity20: rgba(0, 0, 0, 0.2);
$textPrimaryOpacity10: rgba(0, 0, 0, 0.1);

$textWhite: rgba(255, 255, 255, 1);
$textWhiteOpacity80: rgba(255, 255, 255, 0.8);
$textWhiteOpacity60: rgba(255, 255, 255, 0.6);
$textWhiteOpacity40: rgba(255, 255, 255, 0.4);

$textSecond: rgba(122, 122, 122, 1); // #7A7A7A
$textSecondOpacity80: rgba(122, 122, 122, 0.8);
$textSecondOpacity60: rgba(122, 122, 122, 0.6);
$textThird: rgba(29, 31, 35, 1);

$backgroundPrimary: #f5f5f5;
$backgroundInverse: rgba(0, 0, 0, 1);
$backgroundInverseOpacity80: rgba(0, 0, 0, 0.8);
$backgroundInverseOpacity60: rgba(0, 0, 0, 0.6);
$backgroundInverseOpacity40: rgba(0, 0, 0, 0.4);
$backgroundInverseOpacity20: rgba(0, 0, 0, 0.2);
$backgroundInverseOpacity10: rgba(0, 0, 0, 0.1);

$backgroundSecond: #1d1f23;

$borderPrimaryColor: rgba(213, 211, 211, 1);
$borderPrimaryColorOpacity80: rgba(213, 211, 211, 0.8);
$borderPrimaryColorOpacity20: rgba(213, 211, 211, 0.2);
$borderPrimaryColorOpacity10: rgba(213, 211, 211, 0.1);
$borderInverse: rgba(255, 255, 255, 1);

$borderSecond: rgba(34, 34, 34, 1);
$borderSecondOpacity10: rgba(34, 34, 34, 0.1);

$error: rgba(255, 73, 68, 1);
$danger: #ff4944;
$success: rgba(0, 178, 71, 1);
$purple: rgba(123, 56, 165, 1);
$black: #000000;
$blue:#007DC5;

$tooltip:rgba(85, 85, 85, 1);
