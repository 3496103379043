@import 'assets/styles/imports';

.preloader {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	background-color: $backgroundPrimary;

	svg {
		width: 122px;
		height: 55px;
	}
}
