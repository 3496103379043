@import 'assets/styles/imports';

.stickers-emoji-viewer {
	position: relative;
	width: 100%;
	height: 266px;
	padding: 18px 16px 0;
	background-color: #fff;
	border-top: 1px solid $borderPrimaryColorOpacity20;

	&__close {
		display: block;
		position: absolute;
		top: 14px;
		right: 16px;
		width: 24px;
		height: 24px;
		background: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8M15 15L8 8M8 8L15 1L1 15' stroke='%23EB4D3D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
			no-repeat center;
		background-size: 16px;
		user-select: none;
		z-index: 1;
	}

	&__container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	&__controls {
		display: flex;
		width: 100%;
		margin-bottom: 16px;
	}

	&__controls-item {
		margin-right: 4px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__controls-button {
		display: flex;
		align-items: center;
		padding: 0 9px 5px;
		color: $textPrimaryOpacity40;
		text-align: left;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.25px;
		text-transform: uppercase;
		border-bottom: 1px solid transparent;
		transition: color $duration, border $duration;
		user-select: none;

		svg {
			position: relative;
			top: -1px;
			width: 14px;
			height: 13px;
			margin-right: 8px;
			pointer-events: none;

			path {
				fill: $textPrimaryOpacity40;
				transition: fill $duration;
			}
		}

		&--stickers {
			svg {
				width: 18px;
				height: 18px;
				margin-bottom: -2px;
			}
		}

		&.active {
			color: $primary;
			border-bottom-color: $primary;

			svg {
				path {
					fill: $primary;
				}
			}
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
		position: relative;
		flex-grow: 1;
		overflow: hidden;
	}

	&__item {
		width: 100%;
		height: 100%;
		overflow: hidden;

		&--emoji {
			> div {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__item-axis-y {
		flex-grow: 1;
		width: 100%;
		height: 100%;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding-bottom: 8px;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}

	em-emoji-picker {
		width: 100%;
		height: 100%;
		min-height: 211px;
	}
}
