@import 'assets/styles/imports';

.suggest {
	background-color: $backgroundPrimary;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);

	&__item-button {
		display: block;
		width: 100%;
		padding: 7px 16px;
	}

	&__item-container {
		display: flex;
		align-items: center;
		width: 100%;
		overflow: hidden;
	}

	&__item-img {
		width: 28px;
		height: 28px;
		margin-right: 8px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 50%;
	}

	&__item-info {
		display: flex;
		align-items: center;
		width: calc(100% - 36px);
		overflow: hidden;
	}

	&__item-name,
	&__item-text {
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
	}

	&__item-name {
		flex-shrink: 0;
		margin-right: 8px;
		color: #292929;
		font-weight: 500;
	}

	&__item-text {
		color: #7a7a7a;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
