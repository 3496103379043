@import 'assets/styles/_imports';

.chatname-form {
	$block: '.chatname-form';

	display: flex;
	flex-direction: column;
	flex-grow: 1;

	&--no-valid {
		#{$block}__warn {
			opacity: 1;
			visibility: visible;
		}
	}

	&--valid {
		#{$block}__input {
			background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.89729 18C7.43567 18 7.85214 17.772 8.14673 17.3264L17.6953 2.15544C17.9086 1.81347 18 1.52332 18 1.24352C18 0.507772 17.4921 0 16.7607 0C16.263 0 15.9582 0.186529 15.6535 0.673575L6.8465 14.9223L2.32619 9.00518C2.02144 8.60104 1.70655 8.43523 1.25959 8.43523C0.528217 8.43523 0 8.96373 0 9.68912C0 10.0104 0.111738 10.3212 0.375847 10.6425L5.6377 17.3368C5.99323 17.7927 6.36907 18 6.89729 18Z' fill='%2300AB4E'/%3E%3C/svg%3E%0A");
		}
	}

	&__btn {
		margin-top: auto;
	}

	&__title {
		font-size: 16px;
		line-height: 21px;
		letter-spacing: -0.31px;
		color: $textPrimary;
		margin-bottom: 10px;
	}

	&__input {
		padding-left: 13px;
		padding-right: 40px;
		color: $textPrimary;
		height: 48px;
		font-size: 16px;
		line-height: 46px;
		background: $backgroundPrimary;
		border-radius: 4px;
		border: 1px solid $borderPrimaryColor;
		transition: background $duration;
		background-repeat: no-repeat;
		background-size: 18px 18px;
		background-position: right 10px center;

		@include placeholder() {
			color: #7a7a7a;
		}
	}

	&__warn {
		font-size: 12px;
		line-height: 16px;
		margin-top: 8px;
		color: $error;
		opacity: 0;
		visibility: hidden;
		transition: opacity $duration, visibility $duration;

		@include mobile-medium-min {
			margin-top: 6px;
			font-size: 17px;
			line-height: 22px;
		}
	}

	&__btn {
		margin-top: auto;
	}
}
