@import 'assets/styles/_imports';

.select {
	$block: '.select';

	position: relative;
	width: 100%;

	&--opened {
		#{$block}__toggle {
			&::after {
				transform: translateY(-50%) rotate(-90deg);
			}
		}
	}

	&__toggle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding: 12px 43px 11px 16px;
		text-align: left;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $textSecond;
		background: none;
		border: none;
		user-select: none;

		@include mobile-medium-min {
			padding: 15px 60px 15px 19px;
		}

		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
		}

		&:after {
			@include ps-elem;
			position: absolute;
			top: 50%;
			right: 16px;
			width: 9px;
			height: 16px;
			background: url("data:image/svg+xml,%3Csvg viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8L1 15' stroke='%23A1A3A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
				no-repeat center;
			transform: translateY(-50%) rotate(90deg);
			z-index: 1;

			@include mobile-medium-min {
				right: 28px;
				width: 12px;
				height: 20px;
				background-size: contain;
			}
		}

		span {
			font-weight: 500;
			font-size: 13px;
			line-height: 18px;
			text-align: right;
			letter-spacing: -0.08px;
			color: $textPrimaryOpacity40;

			@include mobile-medium-min {
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: -0.098px;
			}
		}
	}

	&__dropdown {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		background-color: #f5f5f5;
		border-radius: 16px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
		z-index: 2;
	}

	&__axis-y {
		flex-grow: 1;
		width: 100%;
		max-height: 300px;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__items {
		padding: 6px 0;
	}

	&__item {
		&:not(:last-child) {
			border-bottom: 1px solid $borderSecondOpacity10;
		}
	}

	&__item-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding: 10px 44px 10px 16px;
		text-align: left;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $textSecond;
		background: none;
		border: none;
		user-select: none;

		&--selected {
			&:after {
				@include ps-elem;
				position: absolute;
				top: 50%;
				right: 16px;
				flex-shrink: 0;
				width: 18px;
				height: 18px;
				margin-left: 12px;
				background: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.89729 18C7.43567 18 7.85214 17.772 8.14673 17.3264L17.6953 2.15544C17.9086 1.81347 18 1.52332 18 1.24352C18 0.507772 17.4921 0 16.7607 0C16.263 0 15.9582 0.186529 15.6535 0.673575L6.8465 14.9223L2.32619 9.00518C2.02144 8.60104 1.70655 8.43523 1.25959 8.43523C0.528217 8.43523 0 8.96373 0 9.68912C0 10.0104 0.111738 10.3212 0.375847 10.6425L5.6377 17.3368C5.99323 17.7927 6.36907 18 6.89729 18Z' fill='%2300AB4E'/%3E%3C/svg%3E%0A")
					no-repeat center;
				transform: translateY(-50%);
			}
		}
	}
}
