@import 'assets/styles/_imports';

.error-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: $backgroundPrimary;
	z-index: 9000000000;

	.error {
		&__container {
			&:first-child {
				margin-left: 0;
				padding-bottom: 0;
			}
		}

		&__text {
			word-break: break-word;
		}
	}
}

.error {
	display: flex;
	flex-direction: column;
	background-color: $backgroundPrimary;
	padding: 16px 20px 32px;
	height: 100%;

	&__header {
		display: flex;
		align-items: center;
		padding-bottom: 16px;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			width: calc(100% + 60px);
			height: 1px;
			background-color: $borderPrimaryColor;
			bottom: 0;
			left: -30px;
		}
	}

	&__container {
		margin-left: 10px;
	}

	&__title {
		color: $textPrimary;
	}

	&__subtitle {
		font-size: 13px;
		line-height: 18px;
		color: $textPrimaryOpacity40;
	}

	&__text {
		margin-top: 24px;
		font-size: 15px;
		line-height: 20px;
		color: $error;
		text-align: center;
	}
}
