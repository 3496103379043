@import 'assets/styles/_imports';

.switcher {
	&__checkbox {
		opacity: 0;
		visibility: hidden;
		width: 0;
		height: 0;
		display: none;

		&:checked + label {
			background-color: $primary;

			&::before {
				left: auto;
				left: calc(100% - 24px - 2px);
			}
		}
	}

	&__label {
		position: relative;
		display: block;
		width: 46px;
		height: 28px;
		border-radius: 34px;
		background-color: #d5d3d3;
		cursor: pointer;
		transition: $duration background;

		&:before {
			content: '';
			position: absolute;
			top: 2px;
			bottom: 2px;
			left: 2px;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			background-color: #ffffff;
			transition: $duration background, $duration left, $duration right;
		}
	}
}
