@import 'assets/styles/_imports';

.header {
	$block: '.header';

	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 44px;
	padding: 0 16px 0 8px;
	z-index: 5;

	&--down {
		top: 48px;
	}

	&--hide {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}

	&__title {
		font-size: 16px;
		line-height: 19px;
		font-weight: 700;
		color: $textPrimary;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__buttons {
		width: 100%;
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	&__button {
		width: 36px;
		height: 36px;
		margin-right: 10px;
		font-size: 0;
		background-position: center bottom;
		background-repeat: no-repeat;
		border-radius: 50%;
		transition: background $duration;

		&:last-child {
			margin-right: 0;
		}

		&--collapse {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='2' rx='1' fill='black' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
		}

		&--expand {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='6' viewBox='0 0 16 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='14' height='4' stroke='black' stroke-opacity='0.6' stroke-width='2'/%3E%3C/svg%3E%0A");
		}

		&--close {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8M15 15L8 8M8 8L15 1L1 15' stroke='black' stroke-opacity='0.6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}
	}

	&__noinet-wrapper {
		position: relative;
		margin-left: auto;
		margin-right: 8px;
		line-height: 0;

		& + #{$block}__slowmode-wrapper {
			margin-left: -2px;
		}
	}

	&__noinet-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 28px;
	}

	&__left {
		display: flex;
		align-items: center;
	}

	&__right {
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	&__slowmode-wrapper {
		position: relative;
		margin-right: 2px;
		line-height: 0;
	}

	&__slowmode-btn {
		display: flex;
		width: 36px;
		height: 28px;

		svg {
			margin: auto;
		}
	}

	&__exit-btn {
		display: flex;
		align-items: center;
		height: 28px;
		width: 28px;
		padding: 0;
		background-color: #fff;
		border-radius: 100px;
		border: 1px solid $primary;
		box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.1);

		svg {
			width: 20px;
			height: 20px;
			margin: auto;
			pointer-events: none;
		}
	}

	&__settings-wrapper {
		position: relative;
		margin-left: 8px;
	}

	&__settings {
		display: flex;
		align-items: center;
		height: 28px;
		width: 28px;
		margin-left: 4px;
		padding: 0;
		background-color: #fff;
		border-radius: 100px;
		border: 1px solid $primary;
		box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.1);

		svg {
			width: 20px;
			height: 20px;
			margin: auto;
			pointer-events: none;
		}
	}

	.tooltip {
		max-width: 300px;
	}
}
