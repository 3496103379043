@import 'assets/styles/imports';

.emotions-anim {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 72px);
	max-height: 600px;
	pointer-events: none;
	z-index: 101;

	&__item {
		width: 34px;
		height: 34px;
		position: absolute;
		bottom: 0;
		right: 50px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		transform-origin: bottom right;
		animation-duration: 5s;
		animation-timing-function: linear;
		animation-fill-mode: forwards;

		&[data-anim='first'] {
			animation-name: animFirst;
		}

		&[data-anim='second'] {
			animation-name: animSecond;
		}
	}
}

@keyframes animFirst {
	0% {
		opacity: 0;
		transform: translateX(0px) scale(0.5);
		bottom: 0;
	}

	21% {
		opacity: 0.35;
		transform: translateX(20px) scale(0.5);
	}

	31% {
		opacity: 0.7;
		transform: translateX(-6px) scale(0.55);
	}

	38% {
		opacity: 1;
		transform: translateX(-20px) scale(0.55);
	}

	48% {
		opacity: 1;
		transform: translateX(-20px) scale(0.65);
	}

	62% {
		opacity: 1;
		transform: translateX(20px) scale(1);
	}

	82% {
		opacity: 0.7;
		transform: translateX(-14px) scale(0.75);
	}

	89% {
		opacity: 0.35;
		transform: translateX(20px) scale(0.58);
	}

	100% {
		opacity: 0;
		transform: translateX(-20px) scale(0.5);
		bottom: 100%;
	}
}

@keyframes animSecond {
	0% {
		opacity: 0;
		transform: translateX(0px) scale(0.5);
		bottom: 0;
	}

	13% {
		opacity: 0.35;
		transform: translateX(-20px) scale(0.58);
	}

	32% {
		opacity: 0.7;
		transform: translateX(22px) scale(0.58);
	}

	40% {
		opacity: 1;
		transform: translateX(22px) scale(0.58);
	}

	50% {
		opacity: 1;
		transform: translateX(-17px) scale(1);
	}

	73% {
		opacity: 0.7;
		transform: translateX(17px) scale(0.75);
	}

	85% {
		opacity: 0.35;
		transform: translateX(-17px) scale(0.58);
	}

	100% {
		opacity: 0;
		transform: translateX(5px) scale(0.5);
		bottom: 100%;
	}
}
