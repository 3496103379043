@import 'assets/styles/_imports';

.waiting-speakers {
	&__text {
		text-align: left;
		font-size: 15px;
		line-height: 20px;
		color: $textPrimary;

		@include mobile-medium-min {
			font-size: 17px;
			line-height: 22px;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__item-avatar,
	&__item-btn {
		flex-shrink: 0;
		width: 36px;
		height: 36px;
		border-radius: 18px;

		@include mobile-medium-min {
			width: 40px;
			height: 40px;
			border-radius: 20px;
		}
	}

	&__item-avatar {
		margin-right: 12px;
		overflow: hidden;

		@include mobile-medium-min {
			margin-right: 20px;
		}
	}

	&__item-avatar-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__item-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(100% - 48px);
		padding: 16px 0;
		border-bottom: 1px solid rgba(84, 84, 88, 0.65);
		flex-grow: 1;
		width: calc(100% - 48px);

		@include mobile-medium-min {
			padding: 11px 0 12px;
			width: calc(100% - 60px);
		}
	}

	&__item-name {
		display: flex;
		align-items: center;
		max-width: calc(100% - 112px);
		margin-right: 16px;
	}

	&__item-name-item {
		display: block;
		margin-right: 4px;
		color: $textPrimary;
		font-size: 17px;
		font-weight: 400;
		line-height: 22px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		@include mobile-medium-min {
			font-size: 16px;
			line-height: 23px;
			font-weight: 500;
		}
	}

	&__item-invited {
		flex-shrink: 0;
		width: 16px;
		height: 16px;
	}

	&__item-admin {
		display: flex;
		align-items: center;
		position: relative;
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color: #a1a3a1;

		&::before {
			content: '';
			display: inline-block;
			width: 16px;
			height: 16px;
			position: relative;
			margin-right: 3px;
			margin-bottom: 1px;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.77042 14.1256H5.26774C5.40068 14.1256 5.49863 14.1606 5.59659 14.2586L6.66011 15.3151C7.56969 16.2318 8.42331 16.2248 9.3329 15.3151L10.3964 14.2586C10.5014 14.1606 10.5923 14.1256 10.7323 14.1256H12.2296C13.51 14.1256 14.1187 13.5169 14.1187 12.2294V10.732C14.1187 10.599 14.1607 10.5011 14.2587 10.4031L15.3152 9.33953C16.2318 8.4299 16.2248 7.57624 15.3152 6.6666L14.2587 5.60302C14.1537 5.49806 14.1187 5.4071 14.1187 5.27415V3.77675C14.1187 2.48927 13.517 1.88051 12.2296 1.88051H10.7323C10.5923 1.88051 10.4944 1.84552 10.3964 1.74756L9.3329 0.683987C8.42331 -0.232647 7.56969 -0.22565 6.66011 0.690984L5.59659 1.74756C5.49863 1.83853 5.40068 1.88051 5.26774 1.88051H3.77042C2.476 1.88051 1.87428 2.47527 1.87428 3.77675V5.27415C1.87428 5.4071 1.83929 5.50506 1.74134 5.60302L0.684817 6.6666C-0.231767 7.57624 -0.224771 8.4299 0.684817 9.33953L1.74134 10.4031C1.83929 10.5011 1.87428 10.599 1.87428 10.732V12.2294C1.87428 13.5169 2.483 14.1256 3.77042 14.1256ZM7.21985 11.4527C6.95397 11.4527 6.74407 11.3407 6.54116 11.0888L4.82694 9.00367C4.701 8.84273 4.63802 8.68179 4.63802 8.49987C4.63802 8.14301 4.92489 7.85612 5.28173 7.85612C5.49863 7.85612 5.66656 7.93309 5.84848 8.164L7.19886 9.87132L10.0886 5.24617C10.2425 5.00126 10.4384 4.87531 10.6553 4.87531C11.0051 4.87531 11.327 5.12022 11.327 5.49107C11.327 5.652 11.243 5.82693 11.1451 5.98087L7.86356 11.0818C7.70963 11.3267 7.48573 11.4527 7.21985 11.4527Z' fill='%230D6EFF'/%3E%3C/svg%3E%0A");
			background-size: cover;
			top: 0;
		}
	}

	&__item-status {
		height: 18px;
		flex-shrink: 0;
		padding-left: 6px;
		padding-right: 6px;
		font-size: 13px;
		line-height: 18px;
		color: $textWhite;
		border-radius: 9px;

		&--club {
			background-color: $primary;
		}

		&--gold {
			background-color: #877342;
		}

		&--platinum {
			background-color: #323232;
		}

		&--silver {
			background-color: #555859;
		}
	}

	&__item-btns {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__item-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		background: $backgroundInverseOpacity20;

		&--close {
			background: rgba($danger, 0.2);
			svg {
				width: 14px;
				height: 14px;
			}
		}

		& + & {
			margin-left: 16px;
		}

		svg {
			pointer-events: none;
		}
	}
}
