@import 'assets/styles/_imports';

.bet {
	$block: '.bet';

	&--win {
		#{$block}__heading {
			background-color: $primary;
		}
	}

	&--lose {
		#{$block}__heading {
			background-color: $textSecond;
		}
	}

	&--null {
		#{$block}__heading {
			background-color: $blue;
		}
	}

	&__heading {
		display: flex;
		justify-content: space-between;
		padding: 8px;
		border-radius: 8px 8px 0px 0px;
	}

	&__heading-right {
		text-align: right;
	}

	&__content {
		padding: 0 8px;
	}

	&__title {
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		color: $textWhite;
	}

	&__total-factor {
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		color: $textWhite;

		b {
			font-weight: 700;
			color: $textWhite;
		}
	}

	&__date {
		margin-bottom: 6px;
		font-size: 12px;
		line-height: 16px;
		color: $textWhite;
	}

	&__events {
		position: relative;
		margin-bottom: 8px;
		border-bottom: 1px solid #f0f0f0;

		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	&__events-btn {
		width: 100%;
		height: 32px;
		color: $primary;
		font-size: 12px;
		line-height: 30px;
		font-weight: 500;
		background-color: #fff;
		border: 1px solid #00ab4e;
		border-radius: 4px;
	}

	&__event {
		padding: 8px 0;
		border-bottom: 1px solid #f0f0f0;

		&:last-child {
			border-bottom: none;
		}
	}

	&__event-title {
		font-size: 12px;
		line-height: 16px;
		font-weight: 400;
		margin-bottom: 0;
	}

	&__event-outcome {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__event-outcome-title {
		font-size: 13px;
		line-height: 16px;
		font-weight: 500;
		margin-bottom: 0;
	}

	&__event-outcome-factor {
		font-size: 13px;
		line-height: 16px;
		font-weight: 700;
		color: $textPrimary;
		margin-left: 20px;
	}

	&__status {
		margin-bottom: 6px;
		font-size: 14px;
		line-height: 16px;
		font-weight: 700;
		color: $textWhite;
	}

	&__bottom {
		.btn {
			width: 100%;
		}
	}
}
