@import 'assets/styles/imports';

.chat {
	$block: '.chat';

	width: 100%;
	height: 100%;
	background-color: $backgroundPrimary;
	overflow: hidden;
	position: relative;

	&--overflow {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(#000, 0.4);
			z-index: 3;
		}
	}

	&__axis-y {
		display: flex;
		width: 100%;
		height: 100%;
		overflow: hidden auto;
		opacity: 0;
		visibility: hidden;
		transition: $duration opacity, $duration visibility;
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding-top: 16px;

		@include mobile-medium-min {
			padding-top: 20px;
		}

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}

		&.visible {
			opacity: 1;
			visibility: visible;
		}
	}

	&__messages {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;

		&--wpinned {
			#{$block}__message:first-child {
				padding-top: 22px;
			}
		}
	}

	&__message {
		margin-top: 8px;

		&:first-child {
			margin-top: auto;

			#{$block}__message-date {
				padding-top: 14px;
			}
		}

		&:last-child {
			padding-bottom: 8px;
		}

		&--no-avatar + & {
			margin-top: 4px;

			#{$block}__message-date {
				padding-top: 22px;
			}
		}

		&--no-avatar + &--highlight {
			margin-top: 8px;
		}

		&--is-hidden {
			#{$block}__message-avatar-img,
			#{$block}__message-header,
			#{$block}__message-text {
				opacity: 0.6;
			}

			#{$block}__message-right {
				max-width: calc(100% - 108px);
				background-color: #fbfbfb;
			}

			#{$block}__message-body {
				opacity: 0.5;
				&:after {
					@include ps-elem;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: -28px;
					width: 18px;
					height: 18px;
					background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23D51C1C' fill-opacity='0.2' /%3E%3Crect x='5' y='10' width='14' height='4' rx='2' fill='%23D51C1C' /%3E%3C/svg%3E")
						no-repeat center;
					background-size: contain;
					pointer-events: none;
					z-index: 1;
				}
			}
		}

		&--right {
			#{$block}__message-container {
				justify-content: flex-end;
			}

			#{$block}__message-left {
				order: 2;
				margin-right: 0;
				margin-left: 8px;
			}

			#{$block}__message-right {
				order: 1;
				align-items: flex-end;
				background-color: #f2fff8;
				max-width: 100%;
			}

			#{$block}__message-sticker {
				margin-left: auto;
			}

			.link-preview__sitename {
				color: $secondary;
				font-weight: 400;
			}

			.reactions {
				margin-left: auto;

				&__items {
					justify-content: flex-end;
				}
			}

			.poll-option-voting {
				&__number {
					color: $textSecondOpacity60;
				}

				&__indicate {
					span {
						background-color: $textSecondOpacity60;
					}
				}
			}

			&#{$block}__message--is-hidden {
				#{$block}__message-body:after {
					right: auto;
					left: -26px;
				}

				#{$block}__message-right {
					max-width: calc(100% - 92px);
				}
			}

			&#{$block}__message--poll-results {
				#{$block}__message-right {
					background-color: $primary;
				}
			}

			&#{$block}__message--sticker {
				&#{$block}__message--reactions {
					.reactions__item-btn {
						color: #7a7a7a;
						background: #fff;

						&--selected {
							color: $textWhite;
							background: $primary;
						}
					}
				}
			}

			&#{$block}__message--reactions {
				#{$block}__message-text {
					display: block;
				}

				.reactions__item-btn {
					color: #7a7a7a;
					background: rgba(240, 240, 240, 0.6);

					&--selected {
						color: $textWhite;
						background: $primary;
					}
				}
			}
		}

		&--highlight {
			padding-top: 12px;

			& + #{$block}__message:not(.chat__message--highlight) {
				margin-top: 20px;
			}

			#{$block}__message-right {
				padding: 0;
				background-color: transparent;
			}

			> #{$block}__message-date,
			& + #{$block}__message #{$block}__message-date {
				padding-top: 0;
				padding-bottom: 20px;
			}
		}

		&--sticker {
			#{$block}__message-body {
				padding: 0;
				background: none;
			}

			#{$block}__message-right {
				background-color: transparent;
				border: none;
			}

			#{$block}__message-text {
				display: flex;
				align-items: flex-end;
			}

			.reactions__item-btn {
				color: #292929;
				background: #fff;

				&--selected {
					color: $textWhite;
					background: $primary;
				}
			}
		}

		&--reactions {
			#{$block}__message-text {
				display: block;
			}

			#{$block}__message-time {
				margin-top: 4px;
			}
		}

		&--submenu {
			#{$block}__message-container {
				z-index: 3;
			}
		}

		&--bet {
			#{$block}__message-right {
				padding: 0 0 8px;
				background-color: #fff;
			}

			#{$block}__message-header {
				margin-bottom: 0;
				padding: 8px 8px 0;
			}

			.reactions {
				padding-left: 8px;
				padding-right: 8px;
			}
		}

		.bet {
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__message-date {
		display: flex;
		justify-content: center;
		padding-top: 16px;
		padding-bottom: 24px;
	}

	&__message-date-item {
		height: 22px;
		padding-left: 8px;
		padding-right: 8px;
		text-align: center;
		font-size: 11px;
		line-height: 22px;
		color: rgba(0, 0, 0, 0.3);
		background-color: #f0f0f0;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 11px;
	}

	&__message-container {
		display: flex;
		align-items: flex-end;
		width: 100%;
		user-select: none;
		position: relative;
		padding-left: 8px;
		padding-right: 8px;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background: $primary;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: $duration opacity, $duration visibility;
		}

		&--lighted {
			&:after {
				opacity: 0.1;
				visibility: visible;
			}
		}
	}

	&__message-not-viewed {
		display: block;
		position: relative;
		left: -16px;
		width: calc(100% + 32px);
		line-height: 36px;
		font-size: 12px;
		color: #7a7a7a;
		background-color: #e9e9e9;
		margin-top: 8px;
		text-align: center;
	}

	&__message-header {
		display: flex;
		align-items: center;
		width: 100%;

		> svg {
			flex-shrink: 0;
			margin-right: 4px;
		}

		&:not(:last-child) {
			margin-bottom: 2px;
		}
	}

	&__message-left {
		width: 35px;
		margin-right: 6px;
		flex-shrink: 0;
	}

	&__message-avatar {
		position: relative;
		width: 100%;
		padding-top: 100%;
		border-radius: 50%;
	}

	&__message-avatar-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
		border-radius: 50%;
		pointer-events: none;
	}

	&__message-right {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background: #ffffff;
		border: 1px solid #f0f0f0;
		border-radius: 8px;
		padding: 8px;
		max-width: calc(100% - 41px);
	}

	&__message-author {
		position: relative;
		margin-right: 4px;
		color: var(--nick-name-color);
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		& + #{$block}__message-author-status {
			margin-left: 4px;
		}

		@include mobile-medium-min {
			font-size: 12px;
			line-height: 16px;
			font-weight: bold;
		}
	}

	&__message-author-status {
		height: 18px;
		flex-shrink: 0;
		padding-left: 6px;
		padding-right: 6px;
		font-size: 13px;
		line-height: 18px;
		color: $textSecond;
		border-radius: 9px;

		&#{$block}__message-author-status--top {
			background-color: rgba(240, 240, 240, 1);
			color: rgba(122, 122, 122, 1);
		}

		&:not(:last-child) {
			margin-right: 4px;
		}
	}

	&__message-author-admin {
		display: flex;
		align-items: center;
		position: relative;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		color: #a1a3a1;

		&::before {
			content: '';
			display: inline-block;
			width: 14px;
			height: 14px;
			margin-right: 4px;
			position: relative;
			margin-bottom: 1px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.79 12.79'%3E%3Cpath d='M3.05,11.3H4.22a.37.37,0,0,1,.24.1l.83.82a1.6,1.6,0,0,0,1.11.57,1.58,1.58,0,0,0,1.1-.57l.83-.82a.33.33,0,0,1,.24-.1H9.74a1.64,1.64,0,0,0,1.18-.38,1.67,1.67,0,0,0,.38-1.19V8.57a.33.33,0,0,1,.1-.24l.82-.83a1.58,1.58,0,0,0,.57-1.11,1.62,1.62,0,0,0-.57-1.11l-.82-.82a.37.37,0,0,1-.1-.24V3.05a1.61,1.61,0,0,0-.38-1.17,1.56,1.56,0,0,0-1.18-.4H8.57a.3.3,0,0,1-.24-.1L7.5.56A1.59,1.59,0,0,0,6.39,0a1.61,1.61,0,0,0-1.1.56l-.83.82a.33.33,0,0,1-.24.1H3.05a1.57,1.57,0,0,0-1.17.39,1.58,1.58,0,0,0-.39,1.18V4.22a.33.33,0,0,1-.1.24l-.83.82A1.65,1.65,0,0,0,0,6.39,1.61,1.61,0,0,0,.56,7.5l.83.83a.3.3,0,0,1,.1.24V9.73a1.6,1.6,0,0,0,.39,1.18A1.57,1.57,0,0,0,3.05,11.3ZM5.78,9.16a.66.66,0,0,1-.32-.08.76.76,0,0,1-.27-.24L3.87,7.27a.72.72,0,0,1-.17-.45.57.57,0,0,1,.17-.42.57.57,0,0,1,.42-.17.55.55,0,0,1,.26.06.7.7,0,0,1,.23.2l1,1.22L8,4.22a.62.62,0,0,1,.52-.33.64.64,0,0,1,.43.16.5.5,0,0,1,.18.4.52.52,0,0,1,0,.21.9.9,0,0,1-.11.22l-2.58,4A.69.69,0,0,1,5.78,9.16Z' fill='%230d6eff'/%3E%3C/svg%3E");
			background-size: cover;
		}
	}

	&__message-author-status {
		height: 18px;
		flex-shrink: 0;
		padding-left: 6px;
		padding-right: 6px;
		font-size: 13px;
		line-height: 18px;
		color: $textWhite;
		border-radius: 9px;

		&--club {
			background-color: $primary;
		}

		&--gold {
			background-color: #877342;
		}

		&--platinum {
			background-color: #323232;
		}

		&--silver {
			background-color: #555859;
		}
	}

	&__message-body {
		position: relative;
		max-width: 100%;
	}

	&__message-text {
		display: flex;
		align-items: flex-end;
		font-size: 15px;
		line-height: 20px;
		white-space: pre-wrap;
		color: $textPrimary;
		word-break: break-word;

		a {
			border-bottom: 1px solid $secondary;
			transition: $duration border-color;
			color: $secondary;

			@include desktop {
				&:hover {
					border-color: transparent;
				}
			}
		}

		&--multiline {
			display: block;
		}
	}

	&__message-text-item {
		margin-right: auto;
	}

	&__message-sticker {
		width: 96px;
		height: 96px;
	}

	&__message-sticker-img {
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&__message-replay {
		display: flex;
		align-items: center;
		position: relative;
		max-width: 100%;
		margin-bottom: 4px;
		text-align: left;
		padding-left: 5px;
		border-left: 2px solid #8a8b8a;
		cursor: pointer;
		z-index: 2;

		#{$block}__message-author {
			margin-bottom: 0;
			color: #7a7a7a;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
		}

		#{$block}__message-text {
			display: block;
			padding: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			background: none;
			border-radius: 0;
			color: #7a7a7a;
			font-size: 12px;
			line-height: 18px;
		}
	}

	&__message-replay-img {
		width: 36px;
		height: 36px;
		margin-right: 4px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		& + #{$block}__message-replay-content {
			width: calc(100% - 40px);
		}
	}

	&__message-replay-content {
		width: 100%;
	}

	&__message-time {
		position: relative;
		margin-left: 9px;
		margin-top: 4px;
		text-align: right;
		font-size: 10px;
		line-height: 12px;
		font-weight: 300;
		color: #a1a3a1;
		white-space: nowrap;
	}

	&__textarea-wrap {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		width: 100%;

		&--warning {
			#{$block}__textarea-item {
				border-color: $danger;
				background-color: rgba($danger, 0.2);
			}

			#{$block}__text-length {
				color: $danger;
			}
		}
	}

	&__textarea-item {
		display: block;
		width: 100%;
		height: 32px;
		min-height: 32px;
		max-height: 78px;
		padding: 5px 48px 5px 12px;
		color: $textPrimary;
		font: 500 15px/22px $font-Inter;
		background: #fafafa;
		border: 1px solid #d5d3d3;
		transition: border $duration, background $duration;
		border-radius: 4px;
		overflow: hidden auto;
		-webkit-user-select: text;
		-ms-overflow-style: none;
		scrollbar-width: none;
		resize: none;

		&:focus {
			outline: none;
		}

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}

		&--wtext {
			padding-right: 75px !important;
		}

		@include placeholder {
			color: #a1a3a1;
		}
	}

	&__submenu {
		position: absolute;
		width: 292px;
		top: calc(100% + 54px);
		left: 0;
		background-color: #ffffff;
		border-radius: 4px;
		opacity: 0;
		visibility: hidden;
		z-index: 103;

		@media screen and (max-width: 355px) {
			width: 280px;
		}

		@include mobile-medium-min {
			width: 322px;
		}

		&--top {
			top: auto;
			bottom: calc(100% + 4px);
		}

		&--right {
			left: auto;
			right: 0;
		}

		&--visible {
			opacity: 1;
			visibility: visible;
			transition: opacity 0.2s 0.1s ease-in, visibility 0.2s 0.1s ease-in;
		}
	}

	&__submenu-items {
		display: flex;
		flex-wrap: wrap;

		&--have-small-2,
		&--have-small-3 {
			#{$block}__submenu-item--small {
				padding: 0;
				border-left: 1px solid #e5e5e5;
				border-bottom: 1px solid #e5e5e5;

				&:first-child {
					border-left: none;
				}

				#{$block}__submenu-btn {
					justify-content: center;
					height: 48px;
					font-size: 0;
					line-height: 0;
					color: transparent;

					&:after {
						content: none;
						display: none;
					}
				}
			}
		}

		&--have-small-2 {
			#{$block}__submenu-item--small {
				width: 50%;
			}
		}

		&--have-small-3 {
			#{$block}__submenu-item--small {
				width: calc(100% / 3);
			}
		}
	}

	&__submenu-item {
		width: 100%;
		padding: 0 20px;
	}

	&__submenu-reactions {
		position: absolute;
		top: -50px;
		left: 0;
		max-width: 100%;
		height: 42px;
		padding: 3px;
		background-color: $backgroundPrimary;
		box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
		border-radius: 21px;
		z-index: 1;
	}

	&__submenu-reactions-axis-x {
		width: 100%;
		height: 100%;
		scrollbar-width: none;
		overflow: scroll hidden;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__submenu-reactions-items {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-shrink: 0;
		margin: 0 -2px;
	}

	&__submenu-reactions-btn {
		flex-shrink: 0;
		display: block;
		width: 36px;
		height: 36px;
		margin: 0 2px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		background-color: rgba(0, 0, 0, 0.05);
		border-radius: 18px;
		cursor: pointer;
		user-select: none;
	}

	&__submenu-btn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding: 13px 0;
		text-align: left;
		font-size: 15px;
		line-height: 21px;
		font-weight: normal;
		letter-spacing: -0.43px;
		color: $textPrimaryOpacity60;

		&:after {
			content: '';
			display: block;
			width: calc(100% + 20px);
			position: absolute;
			left: 0;
			bottom: 0;
			border-bottom: 1px solid #e5e5e5;
		}

		&:last-child {
			border: none;
		}

		&--warn {
			color: $danger;
		}

		&--success {
			color: $success;
		}

		* {
			pointer-events: none;
		}
	}

	&__submenu-icon,
	&__avatar-submenu-icon {
		width: 24px;
		height: 24px;
	}

	&__message-avatar-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	&__avatar-submenu {
		position: absolute;
		width: 280px;
		bottom: 100%;
		left: 0;
		background-color: #ffffff;
		border-radius: 4px;
		z-index: 3;
		padding-left: 20px;
		box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
	}

	&__avatar-submenu-name {
		border-bottom: 1px solid $borderPrimaryColorOpacity20;
		font-size: 15px;
		line-height: 46px;
		color: $textPrimaryOpacity60;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__messages-preloader {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		top: 16px;
		left: 0;
		right: 0;
		pointer-events: none;
		z-index: 1;

		&--down {
			top: 60px;
		}

		svg {
			width: 80px;
			height: 36px;
		}
	}

	&__messages-preloader + &__message {
		margin-top: 0;
	}

	&__scrollbtns {
		display: flex;
		align-items: flex-start;
		position: absolute;
		bottom: 16px;
		right: 12px;
		z-index: 2;
	}

	&__mention {
		display: flex;
		align-items: center;
		width: 24px;
		height: 24px;
		margin-top: 2px;
		margin-right: 6px;
		padding: 0;
		background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%2300AB4E'/%3E%3Cpath d='M12.3079 20C13.471 20 14.5852 19.8465 15.4258 19.5779V18.2446C14.8393 18.5036 13.6078 18.6667 12.3763 18.6667C8.20281 18.6667 5.52474 16.0863 5.52474 12.0671V12.048C5.52474 8.11511 8.23213 5.32374 12.0342 5.32374C15.8754 5.32374 18.4753 7.65468 18.4753 11.1175V11.1367C18.4753 13.3429 17.7422 14.7722 16.5889 14.7722C15.9243 14.7722 15.5528 14.3789 15.5528 13.7074V8.25899H13.8913V9.41966H13.7251C13.3439 8.57554 12.474 8.06715 11.4478 8.06715C9.45388 8.06715 8.08552 9.67866 8.08552 12V12.0192C8.08552 14.446 9.44411 16.0767 11.4771 16.0767C12.6206 16.0767 13.471 15.5492 13.8815 14.5707H14.0476L14.0574 14.6283C14.2431 15.5492 15.113 16.1439 16.2663 16.1439C18.5535 16.1439 20 14.1871 20 11.0887V11.0695C20 6.89688 16.7844 4 12.1417 4C7.31338 4 4 7.2518 4 11.9904V12.0096C4 16.8729 7.24496 20 12.3079 20ZM11.7899 14.5803C10.6072 14.5803 9.88393 13.6211 9.88393 12.0671V12.048C9.88393 10.4748 10.5974 9.53477 11.8094 9.53477C13.0312 9.53477 13.8131 10.5132 13.8131 12.048V12.0671C13.8131 13.6019 13.0312 14.5803 11.7899 14.5803Z' fill='white'/%3E%3C/svg%3E")
			no-repeat center;
		border-radius: 50%;
		user-select: none;
	}

	&__unreaded-messages {
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 2;
	}

	&__unreaded-messages-text {
		width: 28px;
		height: 28px;
		text-align: center;
		font-weight: 400;
		font-size: 15px;
		line-height: 28px;
		color: $textWhite;
		background-color: $primary;
		border-radius: 50%;
	}

	&__unreaded-messages-text {
		margin-bottom: 5px;
	}

	&__unreaded-messages-btn {
		width: 28px;
		height: 28px;
		background: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='13.9989' cy='13.9999' r='8.90909' fill='white'/%3E%3Cpath d='M14 5.03862e-06C6.34269 5.70804e-06 -1.89453e-06 6.32914 -1.22392e-06 14C-5.54496e-07 21.6573 6.34269 28 14.0136 28C21.6709 28 28 21.6573 28 14C28 6.32914 21.6573 4.36919e-06 14 5.03862e-06ZM13.9864 6.8848C14.7183 6.8848 15.2469 7.38626 15.2469 8.10455L15.2469 14.7319L15.1113 17.6321L16.3853 16.06L17.9574 14.4201C18.1607 14.1897 18.4724 14.0542 18.8112 14.0542C19.4753 14.0542 19.9632 14.5286 19.9632 15.1926C19.9632 15.5315 19.8683 15.8025 19.6379 16.0465L14.9758 20.7493C14.6641 21.0745 14.3659 21.2236 13.9864 21.2236C13.5934 21.2236 13.2953 21.0745 12.9835 20.7493L8.33495 16.0465C8.10455 15.8025 7.99613 15.5315 7.99613 15.1926C7.99613 14.5286 8.49758 14.0542 9.16166 14.0542C9.50048 14.0542 9.79864 14.1762 10.0155 14.4201L11.5741 16.0465L12.8616 17.6457L12.726 14.7319L12.726 8.10455C12.726 7.38626 13.2546 6.88481 13.9864 6.8848Z' fill='%2300AB4E'/%3E%3C/svg%3E%0A")
			no-repeat center;
		background-size: contain;
	}

	&__mention,
	&__unreaded-messages-text,
	&__unreaded-messages-btn {
		-webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1))
			drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
		filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))
			drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
	}

	&__textarea-container {
		position: relative;
		width: 100%;
	}

	&__textarea-wrap-btn {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		height: 100%;
		margin-left: 8px;
	}

	&__textarea-stickers-emoji-btn {
		position: absolute;
		width: 24px;
		height: 24px;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;

		&--upper {
			top: auto;
			bottom: 28px;
			transform: none;
		}

		* {
			pointer-events: none;
		}
	}

	&__textarea-send-btn,
	&__textarea-timer-btn {
		display: block;
		width: 32px;
		height: 32px;
		flex-shrink: 0;
		border: none;
		border-radius: 18px;
	}

	&__textarea-send-btn {
		svg {
			width: 100%;
			height: 100%;
			pointer-events: none;
			transform: rotate(-90deg);
			transition: fill $duration;
		}

		&[disabled] {
			pointer-events: none;

			svg {
				fill: rgba(34, 34, 34, 0.6);
			}
		}
	}

	&__textarea-timer-btn {
		color: #7a7a7a;
		text-align: center;
		font-weight: 600;
		font-size: 12px;
		line-height: 32px;
		letter-spacing: -0.4px;
		font-feature-settings: 'tnum';
		font-variant-numeric: tabular-nums;
		background: #d5d3d3;
	}

	&__intro {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		.react-loading-skeleton {
			--base-color: #e9e9e9;
			--highlight-color: #c5c5c5;
		}

		#{$block}__intro-message-body {
			background-color: #f2f2f2;
		}
	}

	&__intro-messages {
		width: 256px;
		margin-bottom: 14px;
	}

	&__intro-message {
		display: flex;
		align-items: flex-end;
		margin-bottom: 8px;

		&:first-child {
			max-width: 197px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__intro-message-avatar {
		width: 32px;
		height: 32px;
		margin-right: 8px;
		background-color: #e9e9e9;
		border-radius: 16px;

		span {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__intro-message-body {
		width: calc(100% - 40px);
		padding: 8px;
		background-color: #ffffff;
		border-radius: 8px;
		border: 1px solid #f0f0f0;

		> span {
			br {
				display: none;
			}
		}

		.react-loading-skeleton {
			display: block;
			max-width: 179px;
			height: 8px;
			margin-top: 8px;
			border-radius: 8px;

			&:first-child {
				margin-top: 0;
				width: 49px;
			}
		}
	}

	&__intro-content {
		text-align: center;
		padding-left: 24px;
		padding-right: 24px;
	}

	&__intro-title,
	&__intro-text {
		font-size: 14px;
		line-height: 24px;
		color: #292929;
	}

	&__intro-title {
		font-weight: 700;
		margin-bottom: 24px;
	}

	&__text-length {
		position: absolute;
		right: 13px;
		bottom: 7px;
		color: $textPrimaryOpacity20;
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
		transition: color $duration;
	}
}

@keyframes blink {
	0%,
	100% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}
}

.ligaloader {
	animation: moveTranslate 2.5s linear infinite;
}

@keyframes moveTranslate {
	0% {
		transform: translate(-45px, 45px);
	}

	16.66% {
		transform: translate(0, 0);
	}

	33.33% {
		transform: translate(45px, 0);
	}

	50% {
		transform: translate(85px, 45px);
	}

	66.66% {
		transform: translate(45px, 0);
	}

	83.33% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(-45px, 45px);
	}
}
