@import 'assets/styles/_imports';

.users {
	$block: '.users';

	display: flex;
	align-items: center;
	position: relative;
	border-radius: 16px;
	transition: background $duration, box-shadow $duration;
	z-index: 2;

	&--have-speakers {
		padding: 3px;
		background-color: #fff;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);

		#{$block}__counter {
			border-color: #e9e9e9;
			background-color: #e9e9e9;

			svg {
				path {
					fill: #8a8b8a;
					transition: fill 0.4s;
				}
			}
		}

		#{$block}__counter-number {
			color: #8a8b8a;
		}

		& + .header__settings {
			width: 34px;
			height: 34px;
			border-color: #fff;

			svg {
				path {
					fill: #8a8b8a;
					transition: fill 0.4s;
				}
			}
		}
	}

	&__counter {
		display: flex;
		align-items: center;
		height: 28px;
		padding: 0 10px;
		background-color: #fff;
		border: 1px solid $primary;
		border-radius: 14px;

		svg {
			width: 17px;
			height: 11px;
			margin-right: 4px;
		}
	}

	&__counter-number {
		font-size: 14px;
		line-height: 17px;
		color: $primary;
		font-variant-numeric: tabular-nums;
	}

	&__click-area {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 0;
		line-height: 0;
		z-index: 1;
	}

	&__list {
		display: flex;
		flex-direction: row;
		margin-right: 1px;
	}

	&__list-item {
		position: relative;
		margin-right: 3px;
		border-radius: 50%;
		padding: 1px;
		border: 1px solid transparent;
		transition: border 0.25s;

		&--active {
			border-color: $primary;
		}
	}

	&__list-item-img {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}
}
