@import 'assets/styles/imports';

.stickers-picker {
	&__items {
		display: flex;
		flex-wrap: wrap;
		min-width: 100%;
		margin-left: -8px;
		margin-right: -8px;
	}

	&__item {
		position: relative;
		width: 72px;
		height: 72px;
		margin: 0 8px 8px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		cursor: pointer;

		@media screen and (max-width: 370px) {
			width: calc(25% - 16px);
			height: auto;

			&:before {
				@include ps-elem;
				display: block;
				width: 100%;
				padding-top: 100%;
				pointer-events: none;
			}
		}
	}
}
