@import 'assets/styles/_imports';

.audio {
	$block: '.audio';

	width: 100%;
	max-width: 300px;
	margin: auto;
	padding: 20px 16px 16px;
	background-color: $backgroundSecond;
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
	border-radius: 8px;

	&__title {
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		text-align: center;
		letter-spacing: -0.31px;
		color: $textPrimary;
		margin-bottom: 16px;
	}

	&__text {
		font-size: 13px;
		line-height: 16px;
		text-align: center;
		letter-spacing: -0.078px;
		color: $textPrimaryOpacity80;
	}

	&__quality {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}

	&__stars {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 26px;
	}

	&__stars-item {
		margin: 0 4px;
	}

	&__stars-btn {
		display: block;
		position: relative;
		width: 44px;
		height: 44px;

		span {
			position: absolute;
			top: 0;
			left: -4px;
			width: calc(100% + 8px);
			height: 100%;
			z-index: 1;
		}
	}

	&__reason {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}

	&__reason-items {
		margin-bottom: 26px;
		padding-left: 8px;
	}

	&__reason-item {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__statistics {
		padding-bottom: 12px;

		&--loaded {
			#{$block}__statistics-loader {
				span {
					animation-duration: 30s;
					animation-name: loadedStart;
				}
			}
		}

		&--loaded-done {
			#{$block}__statistics-loader {
				span {
					&:nth-child(2) {
						animation-duration: 1s;
						animation-name: loadedEnd;
					}
				}
			}
		}
	}

	&__statistics-loader {
		position: relative;
		width: 268px;
		height: 8px;
		margin-top: 32px;
		background-color: rgba(0, 0, 0, 0.4);
		border-radius: 4px;
		overflow: hidden;

		span {
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			background-color: $primary;
			border-radius: 4px;
			animation-fill-mode: forwards;
			animation-iteration-count: 1;
			animation-timing-function: linear;
		}
	}

	&__btn {
		width: 100%;
		margin-top: 20px;
		color: $textPrimaryOpacity40;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		letter-spacing: -0.31px;
		transition: color $duration;

		&[disabled] {
			color: rgba(255, 255, 255, 0.4);
		}

		&--accent {
			color: $primary;
		}
	}
}

@keyframes loadedStart {
	0% {
		width: 0%;
	}

	100% {
		width: 100%;
	}
}

@keyframes loadedEnd {
	100% {
		width: 100%;
	}
}