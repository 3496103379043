@import 'assets/styles/_imports';

.onboard {
	display: flex;
	flex-direction: column;
	background: $backgroundPrimary;
	padding: 16px;
	padding-bottom: 34px;
	height: 100%;
	width: 100%;
	overflow: hidden;

	@include mobile-medium-min {
		padding: 21px 30px 30px;
	}

	&__title {
		color: $textPrimary;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		margin-bottom: 16px;

		@include mobile-medium-min {
			font-size: 20px;
			line-height: 25px;
			margin-bottom: 29px;
		}
	}

	&__text {
		font-size: 15px;
		line-height: 20px;
		color: $textPrimary;

		@include mobile-medium-min {
			font-size: 17px;
			line-height: 22px;
		}
	}

	&__restore-wrapper {
		margin-top: auto;
		text-align: center;
	}

	&__watchers {
		margin-bottom: 20px;
		font-weight: normal;
		font-size: 13px;
		line-height: 18px;
		color: $textPrimaryOpacity40;
	}

	&__btn {
		width: 100%;
	}

	&__loader {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $backgroundPrimary;
		z-index: 100000;

		svg {
			width: 121px;
			height: 55px;
			margin: auto;
		}
	}
}

.ligaloader {
	animation: moveTranslate 2.5s linear infinite;
}

@keyframes moveTranslate {
 0% {
        transform: translate(-45px, 45px);
    }

    16.66% {
        transform: translate(0, 0);
    }

    33.33% {
        transform: translate(45px, 0);
    }

    50% {
        transform: translate(85px, 45px);
    }

    66.66% {
        transform: translate(45px, 0);
    }

    83.33% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-45px, 45px);
    }
}
