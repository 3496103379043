@import 'assets/styles/_imports';

.checkbox {
	&__input {
		opacity: 0;
		visibility: hidden;
		width: 0;
		height: 0;
		display: none;

		&:checked + label {
			&::before {
				opacity: 0;
			}

			&::after {
				opacity: 1;
			}
		}
	}

	&__label {
		display: flex;
		align-items: center;
		position: relative;
		min-height: 22px;
		padding-left: 30px;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: -0.41px;
		color: #ffffff;
		cursor: pointer;

		&:before,
		&:after {
			@include ps-elem;
			position: absolute;
			top: 0;
			left: 0;
			width: 22px;
			height: 22px;
			border-radius: 4px;
			transition: opacity $duration;
		}

		&:before {
			border: 1px solid #aeaeb2;
			z-index: 1;
		}

		&:after {
			background: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.5 0H0.5V22H22.5V0ZM12.8806 9.44895C14.3978 7.92671 15.9148 6.40476 17.3109 5L19.5 7.1899C18.1227 8.57572 16.6169 10.0862 15.1119 11.5958C13.6122 13.1 12.1134 14.6034 10.7435 15.9818L9.73744 17L8.65091 16.0707L3.5 11.6748L5.49598 9.30707L9.56035 12.7818C10.6347 11.7023 11.7577 10.5755 12.8806 9.44895Z' fill='%23FFED00'/%3E%3C/svg%3E%0A")
				no-repeat center;
			opacity: 0;
			z-index: 2;
		}
	}
}
