@import 'assets/styles/imports';

.recording {
	padding-top: 30px;

	&__head {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 44px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__img {
		width: 120px;
		height: 120px;
		margin-bottom: 20px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__suptitle {
		font-weight: 400;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: -0.24px;
		color: #9299a2;
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.38px;
		color: #ffffff;
	}
}
