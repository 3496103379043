@import 'assets/styles/imports';

.link-preview-sceleton {
	width: 320px;
	margin-top: 8px;
	border-radius: 6px;

	@media screen and (max-width: 436px) {
		width: 254px;
	}

	@media screen and (max-width: 366px) {
		width: 206px;
	}

	&__container {
		margin-bottom: 2px;
	}
}

.react-loading-skeleton {
	--base-color: #8c8c8c;
	--highlight-color: #666;
}

.chat__message--right {
	.react-loading-skeleton {
		--base-color: #0da4ff;
		--highlight-color: #0d6eff;
	}
}
