.fade-enter,
.fade-exit-done {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 0.5s ease-in;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity 0.5s ease-in;
}

.fade-up-enter,
.fade-exit-done {
	opacity: 0;
	transform: translateY(8px);
}

.fade-up-enter-active {
	opacity: 1;
	transform: translateY(0px);
	transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;
}

.fade-up-exit {
	opacity: 1;
	transform: translateY(0px);
}

.fade-up-exit-active {
	opacity: 0;
	transform: translateY(8px);
	transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;
}
