@import 'assets/styles/_imports';

.btn {
	$block: '.btn';

	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 13px 20px;
	font-weight: 400;
	font-size: 17px;
	line-height: 22px;
	color: $textWhite;
	background: $primary;
	border: none;
	border-radius: 4px;
	transition: opacity $duration;
	user-select: none;
	outline: none;

	&[disabled] {
		opacity: 0.4;
	}

	&--poll {
		width: 100%;
		font-weight: 400;
		background-color: $primary;
	}

	&--disabled {
		opacity: .4;
	}

	&__right-icon {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;

		path {
			fill: rgba(255, 255, 255, 1);
		}
	}

	&--sm {
		font-size: 12px;
		line-height: 16px;
		padding: 8px;
		border-radius: 4px;
	}
}
