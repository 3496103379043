@import 'assets/styles/_imports';

.settings {
	display: flex;
	flex-direction: column;
	min-height: 100%;

	&__groups {
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__group {
		margin-bottom: 22px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__group-title {
		margin-bottom: 8px;
		padding-left: 16px;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
	}

	&__label {
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color: $textPrimary;
		margin-left: 16px;
		margin-bottom: 8px;
	}

	&__items {
		background: #f5f5f5;
		border-radius: 16px;
	}

	&__item {
		display: flex;
		align-items: center;
		width: 100%;
		user-select: none;

		&:first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&:last-child {
			border-bottom-right-radius: 8px;
			border-bottom-left-radius: 8px;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $borderSecondOpacity10;
		}

		&--switcher {
			padding: 9px 16px;

			& + .settings__item .settings__button {
				padding-top: 13px;
				padding-bottom: 13px;
			}
		}
	}

	&__item-title {
		margin-right: 12px;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.43px;
		color: $textSecond;
	}

	&__item-switcher {
		flex-shrink: 0;
		margin-left: auto;
	}

	&__button {
		display: flex;
		align-items: center;
		position: relative;
		justify-content: space-between;
		width: 100%;
		padding: 12px 43px 11px 16px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $textSecond;
		background: none;
		border: none;
		user-select: none;

		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
		}

		&:after {
			@include ps-elem;
			position: absolute;
			top: 50%;
			right: 16px;
			width: 9px;
			height: 16px;
			background: url("data:image/svg+xml,%3Csvg viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8L1 15' stroke='%23A1A3A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
				no-repeat center;
			transform: translateY(-50%);
			z-index: 1;
		}

		span {
			font-weight: 500;
			font-size: 13px;
			line-height: 18px;
			text-align: right;
			color: $textPrimaryOpacity40;
		}
	}

	&__bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: auto;
	}

	&__delete-account {
		font-size: 14px;
		line-height: 20px;
		color: $textPrimaryOpacity60;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__powered-by {
		font-size: 13px;
		line-height: 18px;
		color: $textPrimaryOpacity40;
	}

	&__text {
		margin-top: 22px;
		padding-left: 16px;
		padding-right: 16px;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.078px;
		color: rgba(255, 255, 255, 0.4);
	}
}
