@keyframes anim3 {
	0% {
		top: 0px;
		transform: translateX(0px) scale(1);
		opacity: 1;
	}
	8% {
		top: 4px;
		transform: translateX(-4px) scale(1);
		opacity: 0.95;
	}
	16% {
		top: 8px;
		transform: translateX(-13px) scale(0.89);
		opacity: 0.9;
	}
	24% {
		top: 10px;
		transform: translateX(-23px) scale(0.82);
		opacity: 0.85;
	}
	32% {
		top: 10px;
		transform: translateX(-34px) scale(0.71);
		opacity: 0.8;
	}
	40% {
		top: 9px;
		transform: translateX(-41px) scale(0.71);
		opacity: 0.75;
	}
	48% {
		top: 12px;
		transform: translateX(-48px) scale(0.64);
		opacity: 0.7;
	}
	56% {
		top: 15px;
		transform: translateX(-56px) scale(0.57);
		opacity: 0.65;
	}
	64% {
		top: 17px;
		transform: translateX(-63px) scale(0.54);
		opacity: 0.6;
	}
	72% {
		top: 21px;
		transform: translateX(-72px) scale(0.43);
		opacity: 0.55;
	}
	80% {
		top: 24px;
		transform: translateX(-78px) scale(0.36);
		opacity: 0.5;
	}
	88% {
		top: 25px;
		transform: translateX(-83px) scale(0.36);
		opacity: 0.45;
	}
	96% {
		top: 26px;
		transform: translateX(-89px) scale(0.32);
		opacity: 0.4;
	}
	100% {
		top: 27px;
		transform: translateX(-92px) scale(0.32);
		opacity: 0;
	}
}
