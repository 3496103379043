@import 'assets/styles/_imports';

.poll-settings {
	&__title {
		font-size: 15px;
		line-height: 20px;
		font-weight: 500;
		color: #7a7a7a;
		margin-bottom: 8px;
	}

	&__group {
		background-color: #f0f0f0;
		border-radius: 16px;
		overflow: hidden;
		margin-bottom: 16px;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 44px;
		margin-left: 16px;
		padding-right: 8px;
		color: #7a7a7a;
		font-size: 14px;
		line-height: 20px;
		border-bottom: 1px solid rgba(#d5d3d3, 0.5);

		&:last-child {
			border-color: transparent;
		}
	}
}
