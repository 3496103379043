@import 'assets/styles/_imports';

.poll-toggle {
	$block: '.poll-toggle';

	display: flex;
	justify-content: flex-end;
	max-width: calc(100% - 10px);
	position: absolute;
	top: 50px;
	right: 10px;
	z-index: 4;

	@include mobile-medium-min {
		top: 58px;
	}

	&--down {
		top: 92px;

		@include mobile-medium-min {
			top: 106px;
		}
	}

	&--active {
		#{$block}__option {
			&:before {
				opacity: 1;
				visibility: visible;
			}
		}

		#{$block}__button-ico {
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%2300AB4E'/%3E%3Crect x='10' y='6' width='4' height='12' rx='2' fill='%23F0F0F0'/%3E%3Crect x='5' y='7' width='3' height='10' rx='1.5' fill='%23F0F0F0'/%3E%3Crect x='16' y='7' width='3' height='10' rx='1.5' fill='%23F0F0F0'/%3E%3C/svg%3E%0A");
			}
		}
	}

	&__options {
		display: flex;
		margin-right: 4px;

		&--votes {
			margin-right: 11px;
		}
	}

	&__option {
		position: relative;
		width: 36px;
		height: 36px;
		margin-right: 10px;
		border-radius: 18px;

		&:last-child {
			margin-right: 0;
		}

		&:before {
			@include ps-elem;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			-webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 16.8C1 9.8654 1.78335 6.08086 3.93211 3.93211C6.08086 1.78335 9.8654 1 16.8 1H19.2C26.1346 1 29.9191 1.78335 32.0679 3.93211C34.2166 6.08086 35 9.8654 35 16.8V19.2C35 26.1346 34.2166 29.9191 32.0679 32.0679C29.9191 34.2166 26.1346 35 19.2 35H16.8C9.8654 35 6.08086 34.2166 3.93211 32.0679C1.78335 29.9191 1 26.1346 1 19.2V16.8Z' stroke='url(%23paint0_linear_1_3)' stroke-width='2' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_3' x1='0' y1='0' x2='0' y2='100%25' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2376FFA5' /%3E%3Cstop offset='1' stop-color='%23EBFF00' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
			mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 16.8C1 9.8654 1.78335 6.08086 3.93211 3.93211C6.08086 1.78335 9.8654 1 16.8 1H19.2C26.1346 1 29.9191 1.78335 32.0679 3.93211C34.2166 6.08086 35 9.8654 35 16.8V19.2C35 26.1346 34.2166 29.9191 32.0679 32.0679C29.9191 34.2166 26.1346 35 19.2 35H16.8C9.8654 35 6.08086 34.2166 3.93211 32.0679C1.78335 29.9191 1 26.1346 1 19.2V16.8Z' stroke='url(%23paint0_linear_1_3)' stroke-width='2' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_3' x1='0' y1='0' x2='0' y2='100%25' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2376FFA5' /%3E%3Cstop offset='1' stop-color='%23EBFF00' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
			background: linear-gradient(180deg, #76ffa5 0%, #ebff00 100%) no-repeat 0 0;
			background-size: 100% 100%;
			opacity: 0;
			visibility: hidden;
			transition: opacity $duration, visibility $duration;
			z-index: 1;
		}
	}

	&__option-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		-webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 36 36' style='enable-background:new 0 0 36 36;' xml:space='preserve'%3E%3Cpath style='fill:%23C4C4C4;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3Cpath style='fill:none;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3Cpath style='fill:none;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3C/svg%3E");
		mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 36 36' style='enable-background:new 0 0 36 36;' xml:space='preserve'%3E%3Cpath style='fill:%23C4C4C4;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3Cpath style='fill:none;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3Cpath style='fill:none;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3C/svg%3E");
	}

	&__option-numbers {
		position: absolute;
		right: -9px;
		bottom: 0;
		min-width: 22px;
		height: 20px;
		padding: 0 4px;
		text-align: center;
		font-size: 12px;
		line-height: 20px;
		color: #000;
		background-color: #c1c1c1;
		border-radius: 11px;
		z-index: 1;
	}

	&__button {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}

	&__button-content {
		display: flex;
		align-items: center;
		min-width: 36px;
		border-radius: 18px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
		transition: background $duration, box-shadow $duration;
		overflow: hidden;

		&--accent {
			background-color: $primary;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

			#{$block}__button-text {
				margin-right: 0;
				opacity: 1;
			}

			#{$block}__button-ico {
				&:before {
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
				}
			}
		}
	}

	&__button-ico {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 36px;
		padding: 0;
		border-radius: 18px;
		transition: background $duration, box-shadow $duration;
		pointer-events: none;

		&:before {
			@include ps-elem;
			width: 24px;
			height: 24px;
			background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%23C1C1C1' /%3E%3Crect x='10' y='6' width='4' height='12' rx='2' fill='%23242424' /%3E%3Crect x='5' y='7' width='3' height='10' rx='1.5' fill='%23242424' /%3E%3Crect x='16' y='7' width='3' height='10' rx='1.5' fill='%23242424' /%3E%3C/svg%3E")
				no-repeat center;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 12px;
			transition: background $duration;
		}
	}

	&__button-text {
		margin-right: -110px;
		padding-right: 20px;
		color: $textWhite;
		font-weight: 600;
		font-size: 13px;
		line-height: 16px;
		opacity: 0;
		transition: opacity $duration, margin $duration;
	}

	&__submenu {
		position: absolute;
		width: 280px;
		top: calc(100% + 12px);
		right: 6px;
		padding: 0 20px;
		border-radius: 4px;
		background-color: #ffff;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		z-index: 3;
	}

	&__submenu-item {
		border-bottom: 1px solid #e5e5e5;

		&:last-child {
			border: none;
		}
	}

	&__submenu-button {
		width: 100%;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		line-height: 47px;
		color: #292929;

		svg {
			width: 24px;
			height: 24px;
			margin-left: 12px;
		}
	}

	&__tooltip {
		position: absolute;
		top: -1px;
		right: 40px;
		width: max-content;
		max-width: 350px;
		padding: 8px 34px 8px 16px;
		background-color: #e9e9e9;
		border-radius: 8px;
		box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.2);
		z-index: 1;

		@media screen and (max-width: 430px) {
			max-width: calc(100vw - 78px);
		}

		&:after {
			@include ps-elem;
			position: absolute;
			top: 10px;
			right: -8px;
			width: 8px;
			height: 20px;
			background: url("data:image/svg+xml,%3Csvg width='8' height='20' viewBox='0 0 8 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 10C8 7 -6.99378e-07 4.0001 -8.74228e-07 3.49691e-07L0 20C-1.73749e-07 16.0251 8 13 8 10Z' fill='%23e9e9e9'/%3E%3C/svg%3E%0A")
				no-repeat center;
		}
	}

	&__tooltip-close {
		display: flex;
		position: absolute;
		top: 6px;
		right: 8px;
		width: 24px;
		height: 24px;
		padding: 0;
		z-index: 1;

		* {
			pointer-events: none;
		}

		svg {
			margin: auto;
			width: 18px;
			height: 18px;
		}
	}

	&__tooltip-text {
		margin-bottom: 8px;
		font-weight: 400;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimary;
		word-break: break-word;
	}

	&__tooltip-vote {
		display: block;
		padding: 0;
		text-align: left;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: -0.43px;
		color: $primary;
		background: none;
		border: none;
	}
}
