@font-face {
	font-family: 'Inter';
	src: url('Inter-Regular.woff2') format('woff2'), url('Inter-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('Inter-SemiBold.woff2') format('woff2'), url('Inter-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('Inter-Medium.woff2') format('woff2'), url('Inter-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
