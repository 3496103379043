@import 'assets/styles/_imports';

.poll-modal {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);

	&__body {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 100%;
		height: 100%;
		padding-left: 8px;
		padding-right: 8px;
		z-index: 1;
		overflow: hidden;
	}

	&__close {
		display: block;
		position: absolute;
		top: 16px;
		right: 20px;
		width: 24px;
		height: 24px;
		background: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8M15 15L8 8M8 8L15 1L1 15' stroke='%23EB4D3D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
			no-repeat center;
		background-size: 16px;
		user-select: none;
		z-index: 1;
	}

	&__axis-y {
		display: flex;
		width: 100%;
		max-height: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		margin: 24px 0;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__container {
		position: relative;
		width: 100%;
		max-width: 500px;
		margin: auto;
		padding: 18px 24px 24px;
		background-color: #fff;
		border-radius: 6px;
	}

	&__top {
		margin-bottom: 16px;
		padding-right: 30px;
	}

	&__suptitle {
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.023px;
		color: rgba($textPrimary, 0.4);
		margin-bottom: 6px;
	}

	&__title {
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimary;
		word-wrap: break-word;
	}

	&__content {
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__results {
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.23px;
		color: rgba($textPrimary, 0.4);
	}
}
