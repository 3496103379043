:root {
	--nick-name-color: #00000099;
	--nick-name-color1: #00ab4e;
	--nick-name-color2: #8a8b8a;
	--nick-name-color3: #c73834;
	--nick-name-color4: #d46e3b;
	--nick-name-color5: #00a775;
	--nick-name-color6: #007dc5;
	--nick-name-color7: #b1a000;
}
