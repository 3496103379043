@import 'assets/styles/_imports';

.poll-question {
	&__title {
		font-size: 15px;
		line-height: 20px;
		font-weight: 500;
		color: #7a7a7a;
		margin-bottom: 8px;
	}

	&__input-container {
		position: relative;
		margin-bottom: 16px;
	}

	&__input {
		width: 100%;
		height: 48px;
		padding: 13px 13px 15px;
		line-height: 20px;
		border-radius: 4px;
		border: 1px solid #d5d3d3;
		font-size: 16px;
		line-height: 20px;
		color: $textPrimary;
		background-color: #fafafa;
		overflow: hidden;
		-ms-overflow-style: none;
		scrollbar-width: none;
		resize: none;

		@include placeholder() {
			color: #a1a3a1;
		}

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__icounter {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 13px;
		line-height: 20px;
		font-weight: 400;
		color: #7a7a7a;

		&--top {
			top: -18px;
		}
	}
}
