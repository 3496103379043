@import 'assets/styles/imports';

.emotions {
	position: absolute;
	bottom: calc(100% + 8px);
	right: 8px;
	max-width: calc(100% - 16px);
	height: 42px;
	padding: 3px;
	background-color: $backgroundPrimary;
	box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
	border-radius: 21px;
	overflow: hidden;
	z-index: 1;

	&__items {
		display: flex;
		align-items: center;
		margin: 0 -2px;
		height: 100%;
	}

	&__item {
		flex-shrink: 0;
		display: block;
		width: 36px;
		height: 36px;
		margin: 0 2px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		background-color: rgba(0, 0, 0, 0.05);
		border-radius: 18px;
		transition: transform 0.35s;
		cursor: pointer;
		user-select: none;

		&--active {
			transform: scale(0.9);
		}
	}
}
