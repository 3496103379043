@import 'assets/styles/_imports';

.star {
	$block: '.star';

	&--selected {
		#{$block}__ico {
			path {
				fill: $primary;
			}
		}
	}

	&__ico {
		width: 44px;
		height: 44px;

		path {
			stroke: $primary;
			transition: fill $duration;
		}
	}
}
