@import 'assets/styles/imports';

.link-preview {
	position: relative;
	max-width: 327px;
	margin-top: 6px;
	padding-left: 7px;
	cursor: pointer;
	user-select: none;

	* {
		pointer-events: none;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 2px;
		background-color: $textPrimary;
		border-radius: 5px;
		z-index: 1;
	}

	&__sitename {
		margin-bottom: 4px;
		font-size: 14px;
		font-weight: bold;
		line-height: 17px;
		color: $textPrimary;
	}

	&__description {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: $textPrimary;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__image {
		max-width: 320px;
		min-width: 238px;
		width: 100%;
		height: 166px;
		margin-top: 5px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 4px;
	}
}
