@import 'assets/styles/_imports';

$defaultBackgroundColor: #0d6eff;
$correctBackgroundColor: #1b7e49;
$notCorrectBackgroundColor: #6b1a2b;

@mixin defaultMarker {
	background-image: url("data:image/svg+xml,%3Csvg width='28' height='30' viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14' cy='15' r='11' fill='white'/%3E%3Cpath d='M12.8318 20C13.1309 20 13.3623 19.8733 13.526 19.6258L18.8307 11.1975C18.9492 11.0075 19 10.8463 19 10.6908C19 10.2821 18.7178 10 18.3115 10C18.035 10 17.8657 10.1036 17.6964 10.3742L12.8036 18.2902L10.2923 15.0029C10.123 14.7784 9.94808 14.6862 9.69977 14.6862C9.29345 14.6862 9 14.9799 9 15.3828C9 15.5613 9.06208 15.734 9.2088 15.9125L12.1321 19.6315C12.3296 19.8849 12.5384 20 12.8318 20Z' fill='black'/%3E%3C/svg%3E%0A");
}

@mixin correctMarker {
	background-image: url("data:image/svg+xml,%3Csvg width='28' height='30' viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14' cy='15' r='11' fill='white'/%3E%3Cpath d='M12.8318 20C13.1309 20 13.3623 19.8733 13.526 19.6258L18.8307 11.1975C18.9492 11.0075 19 10.8463 19 10.6908C19 10.2821 18.7178 10 18.3115 10C18.035 10 17.8657 10.1036 17.6964 10.3742L12.8036 18.2902L10.2923 15.0029C10.123 14.7784 9.94808 14.6862 9.69977 14.6862C9.29345 14.6862 9 14.9799 9 15.3828C9 15.5613 9.06208 15.734 9.2088 15.9125L12.1321 19.6315C12.3296 19.8849 12.5384 20 12.8318 20Z' fill='%2300AB4E'/%3E%3C/svg%3E%0A");
}

@mixin notCorrectMarker {
	background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='12' fill='white' /%3E%3Cpath d='M10.3334 10.3333L15 15M19.6667 19.6666L15 15M15 15L19.6667 10.3333L10.3334 19.6666' stroke='%23FF4944' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.poll-message {
	$block: '.poll-message';

	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding-left: 8px;
	padding-right: 8px;

	&--not-correct {
		#{$block}__container {
			background-color: $danger;
		}
	}

	&--quiz#{&}--correct {
		#{$block}__item-content {
			&:before {
				@include correctMarker;
			}
		}
	}

	&--quiz#{&}--not-correct {
		#{$block}__item-content {
			&:before {
				@include notCorrectMarker;
			}
		}
	}

	&--quiz-multiple {
		#{$block}__item {
			&--correct {
				#{$block}__item-content {
					&:before {
						@include correctMarker;
					}
				}
			}

			&--not-correct {
				#{$block}__item-content {
					&:before {
						@include notCorrectMarker;
					}
				}
			}
		}
	}

	&__container {
		max-width: 310px;
		min-width: 230px;
		padding: 6px;
		background-color: $primary;
		border-radius: 9px;
	}

	&__title {
		margin-bottom: 6px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: $textWhiteOpacity60;
	}

	&__question {
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $textWhiteOpacity80;
		word-break: break-word;
	}

	&__items {
		margin-top: 8px;
	}

	&__item {
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
		margin-bottom: 6px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__item-img {
		width: 42px;
		height: 42px;
		margin-right: 6px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 5px;

		& + #{$block}__item-content {
			width: calc(100% - 48px);
		}
	}

	&__item-content {
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
		min-height: 42px;
		padding-right: 40px;

		&:before {
			@include ps-elem;
			position: absolute;
			top: 50%;
			right: -4px;
			width: 30px;
			height: 30px;
			background-repeat: no-repeat;
			background-position: center;
			@include defaultMarker;
			transform: translateY(-50%);
			z-index: 1;
		}
	}

	&__item-title {
		display: block;
		max-width: 208px;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textWhite;

		& + #{block}__item-voting {
			margin-top: 4px;
		}
	}

	&__item-voting {
		width: 100%;
		display: flex;
		align-items: center;
	}

	&__item-voting-percent {
		margin-right: 11px;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $textPrimary;
	}

	&__item-voting-indicate {
		width: 88px;
		height: 5px;

		span {
			display: block;
			height: 100%;
			background-color: $backgroundInverse;
			border-radius: 100px;
		}
	}
}
