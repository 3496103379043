@import 'assets/styles/imports';

.action-message {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 44px;
	padding: 5px 18px 5px 8px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	z-index: 1;

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 40px;
		height: 30px;
		margin-right: 11px;
		border-right: 1px solid #8a8b8a;
	}

	&__content {
		width: calc(100% - 87px);
	}

	&__title,
	&__text {
		width: 100%;
		font-size: 12px;
		line-height: 16px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__title {
		color: #292929;
	}

	&__text {
		color: #7a7a7a;
	}

	&__close {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		margin-left: 12px;
	}
}
