@import 'assets/styles/imports';

.event {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: $backgroundPrimary;
	z-index: 100000;
	padding: 16px;
	padding-top: 24px;
	display: flex;
	flex-direction: column;

	&--min {
		position: relative;
		height: auto;
		margin-top: 44px;
		z-index: 1;

		& + .chat {
			.chat__messages-preloader {
				position: absolute;
				top: 0;
			}
		}
	}

	&__title {
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		color: $textPrimary;
	}

	&__container {
		margin: auto 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__name {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
		color: $textPrimary;
	}

	&__countdown {
		display: flex;
		flex-direction: column;
		background-color: #f0f0f0;
		border-radius: 12px;
		padding: 23px 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__countdown-title {
		font-size: 13px;
		line-height: 18px;
		font-weight: 400;
		color: $textSecond;
		margin-bottom: 11px;
	}

	&__countdown-time {
		display: flex;
		align-items: center;
	}

	&__countdown-delimeter {
		font-size: 16px;
		line-height: 19px;
		font-weight: normal;
		color: #afafaf;
		margin: 0 8px;
		margin-bottom: 9px;
	}

	&__countdown-item {
		width: 67px;
		height: 49px;
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35);
		border-radius: 8px;
		background-color: #d5d3d3;
		margin-bottom: 9px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__countdown-number {
		font-size: 16px;
		line-height: 19px;
		font-weight: 700;
		color: $textPrimary;
		margin-bottom: 1px;
	}

	&__countdown-text {
		font-size: 9px;
		line-height: 11px;
		font-weight: 400;
		color: #7a7a7a;
	}

	&__loading {
		position: absolute;
		top: calc(50% - 23px);
		left: calc(50% - 40px);
		width: 80px;
		height: 36px;
	}
}
